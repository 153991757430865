import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
  HostListener,
} from "@angular/core";
import { LayoutService, LayoutInitService } from "../../core";
import QFLayoutContent from "../../../../assets/js/layout/base/content";
import { QFUtil } from "../../../../assets/js/components/util";
import QFLayoutBrand from "../../../../assets/js/layout/base/brand";
import QFLayoutAside from "../../../../assets/js/layout/base/aside";
import QFLayoutAsideMenu from "../../../../assets/js/layout/base/aside-menu";
import QFLayoutAsideToggle from "../../../../assets/js/layout/base/aside-toggle";
import QFLayoutStickyCard from "../../../../assets/js/layout/base/sticky-card";
import QFLayoutStretchedCard from "../../../../assets/js/layout/base/stretched-card";
import QFLayoutHeader from "../../../../assets/js/layout/base/header";
import QFLayoutHeaderMenu from "../../../../assets/js/layout/base/header-menu";
import { Subscription, Observable, BehaviorSubject } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NewworkflowComponent } from "src/app/modals/newworkflow/newworkflow.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DatePipe } from "@angular/common";
import { MatSort } from "@angular/material/sort";
import { ConfirmdeleteComponent } from "src/app/modals/confirmdelete/confirmdelete.component";
import { ToastrService } from "ngx-toastr";
import { CopyworkflowComponent } from "src/app/modals/copyworkflow/copyworkflow.component";
import { WorkflowhistoryComponent } from "src/app/modals/workflowhistory/workflowhistory.component";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as CryptoJS from "crypto-js";

declare function activateDatepicker(): any;

import $ from "jquery";
import { Config } from "src/environments/environment.prod";

declare function activateDatepicker(): any;

export interface FormsData {
  _id: string;
  form_id: string;
  form_name: string;
  workflowdata: any;
  stage: string;
  status: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  action: any;
}

@Component({
  selector: "app-dashboardlist",
  templateUrl: "./dashboardlist.component.html",
  styleUrls: ["./dashboardlist.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardlistComponent implements OnInit, AfterViewInit {
  @ViewChild("matpaginator", { static: true }) matpaginator: MatPaginator;
  displayedColumns = ["form_id", "form_name", "action"];
  TABLE_DATA: FormsData[] = [];
  dataSource = new MatTableDataSource(this.TABLE_DATA);
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  form_id: any = 0;
  stagesform: FormGroup;
  form: FormGroup;
  headerLogo: string;
  selfLayout = "default";
  asideCSSClasses: string;
  headerCSSClasses: string;
  contentClasses = "";
  contentExtended: false;
  contentContainerClasses = "";
  asideSelfMinimizeToggle = false;
  disableAsideSelfDisplay: boolean;
  brandSkin: string;
  asideMenuCSSClasses: string;
  asideMenuScroll = 1;
  asideMenuDropdown;
  ulCSSClasses: string;
  brandClasses: string;
  headerContainerCSSClasses: string;
  isWorkflowPublish: boolean = false;
  extrasQuickPanelDisplay = false;
  headerMenuSelfDisplay: boolean;
  asideSelfDisplay: boolean;
  headerMenuHTMLAttributes: any = {};
  asideHTMLAttributes: any = {};
  headerMobileAttributes = {};
  headerHTMLAttributes: any = {};
  asideMenuHTMLAttributes: any = {};
  publisheddata: any[] = [];
  checkedoutdata: any[] = [];
  confirmFormDeleteDialogRef = null;
  workflowId: any;
  workflowname: any;
  workflowstage: any;
  formname: any;
  username: any;
  qformid: any;
  activeTabId = 1;
  workdata: any[] = [];
  rechistory: any[] = [];
  chkpubCheckOut: any = [];
  userList: any = [];
  extrasQuickPanelOffcanvasDirectionCSSClass = "offcanvas-right";
  extrasQuickPanelOffcanvasDirectionCSSClassStages = "offcanvas-right";
  stagesCollapsed = true;
  stageListIndex = 0;
  newStages = true;
  workflowStages: any = [];
  expandedIndex = -1;
  unsaved = false;
  isApproved: boolean = true;
  dueDate: any;
  appDesc: any;
  approverId = 0;
  approverName: any;
  decryptedParam: string;
  encryptedParam: string;
  qformsLoaded: boolean = false;

  @ViewChild("ktHeaderMenu", { static: true }) ktHeaderMenu: ElementRef;
  @ViewChild("ktAside", { static: true }) ktAside: ElementRef;
  @ViewChild("ktHeaderMobile", { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild("ktHeader", { static: true }) ktHeader: ElementRef;
  loader$: Observable<number>;
  access_token: any;
  stageName: any;
  stageValue: any;
  beginId = 0;
  editStageName: any = [];

  swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary",
      cancelButton: "btn btn-secondary",
    },
    buttonsStyling: false,
  });

  private loaderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  private unsubscribe: Subscription[] = [];

  ploader: boolean = false;
  objUser: any;
  verifiedUser: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private initService: LayoutInitService,
    private layout: LayoutService,
    public api: WorkFlowService,
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    public datepipe: DatePipe
  ) {
    this.initService.init();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.encryptedParam = params["param"].toString();
      const decData = CryptoJS.enc.Base64.parse(this.encryptedParam).toString(
        CryptoJS.enc.Utf8
      );
      this.decryptedParam = CryptoJS.AES.decrypt(
        decData,
        Config.secretKey
      ).toString(CryptoJS.enc.Utf8);
    });
    this.qformsLoaded = false;
    this.ploader = true;
    this.verifiedUser = false;
    localStorage.setItem("wkfiHubToken", this.decryptedParam.replace(/"/g, ""));
    this.access_token = this.decryptedParam.replace(/"/g, "");
    this.selfLayout = this.layout.getProp("self.layout");
    this.brandClasses = this.layout.getProp("brand");
    this.asideCSSClasses = this.layout.getStringCSSClasses("aside");
    this.headerCSSClasses = this.layout.getStringCSSClasses("header");
    this.contentClasses = this.layout.getStringCSSClasses("content");
    this.contentExtended = this.layout.getProp("content.extended");
    this.contentContainerClasses =
      this.layout.getStringCSSClasses("content_container");
    this.headerLogo = this.getLogoURL();
    this.disableAsideSelfDisplay =
      this.layout.getProp("aside.self.display") === false;
    this.brandSkin = this.layout.getProp("brand.self.theme");
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses("aside_menu");
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes("aside_menu");
    this.asideMenuScroll = this.layout.getProp("aside.menu.scroll") ? 1 : 0;
    this.asideMenuDropdown = this.layout.getProp("aside.menu.dropdown")
      ? "1"
      : "0";
    this.ulCSSClasses = this.layout.getProp("aside_menu_nav");
    this.headerContainerCSSClasses =
      this.layout.getStringCSSClasses("header_container");
    this.headerMenuSelfDisplay = this.layout.getProp(
      "header.menu.self.display"
    );
    this.asideSelfDisplay = this.layout.getProp("aside.self.display");
    this.asideHTMLAttributes = this.layout.getHTMLAttributes("aside");
    this.headerMobileAttributes =
      this.layout.getHTMLAttributes("header_mobile");
    this.headerHTMLAttributes = this.layout.getHTMLAttributes("header");
    this.asideSelfMinimizeToggle = this.layout.getProp(
      "aside.self.minimize.toggle"
    );
    // this.extrasQuickPanelDisplay = this.layout.getProp(
    //   "extras.quickPanel.display"
    // );
    this.extrasQuickPanelOffcanvasDirectionCSSClass = `offcanvas-${this.layout.getProp(
      "extras.quickPanel.offcanvas.direction"
    )}`;
    this.extrasQuickPanelOffcanvasDirectionCSSClass = "";

    this.extrasQuickPanelOffcanvasDirectionCSSClassStages = `offcanvas-${this.layout.getProp(
      "extras.quickPanel.offcanvas.direction"
    )}`;
    this.extrasQuickPanelOffcanvasDirectionCSSClassStages = "";

    this.qformid = this.route.snapshot.paramMap.get("formid");

    this.stageName = new FormControl("", [Validators.required]);
    this.stageValue = new FormControl("", [Validators.required]);
    this.stagesform = this.fb.group({
      stageName: this.stageName,
      stageValue: this.stageValue,
      workflowStates: this.fb.array([]),
    });

    this.generateUserId()
      .then(() => {
        this.getDataList(this.qformid, false);
      })
      .catch((error) => {
        console.error("Error generating user ID:", error);
      });
  }

  initItemRows(
    valPending,
    valInProgress,
    valApproved,
    valRejected,
    valCompleted,
    valRequest
  ) {
    return this.fb.group({
      pending: valPending,
      inprogress: valInProgress,
      approved: valApproved,
      rejected: valRejected,
      completed: valCompleted,
      requestedmoreinfo: valRequest,
    });
  }
  get formArr() {
    return this.stagesform.get("workflowStates") as FormArray;
  }

  setActiveTab(tabId: number) {
    this.activeTabId = tabId;
  }

  getActiveTabCSSClass(tabId: number) {
    if (tabId !== this.activeTabId) {
      return "";
    }
    return "active";
  }

  changeStage(mode, workflowId) {
    this.extrasQuickPanelDisplay = true;
    this.extrasQuickPanelOffcanvasDirectionCSSClassStages =
      "offcanvas-right offcanvas-on";
    this.workflowStages = [];
    this.api.generateWorkflowData(workflowId).subscribe((data) => {
      data["workflow_data"].forEach((element) => {
        element.stage.forEach((elmChild) => {
          this.workflowStages.push({
            stageName: elmChild.stageName,
            stageValue: elmChild.stageValue,
            states: elmChild.states,
            isExpand: elmChild.isExpand,
            isActive: elmChild.isActive,
          });
          this.formArr.push(
            this.initItemRows(
              elmChild.states.pending,
              elmChild.states.inprogress,
              elmChild.states.approved,
              elmChild.states.rejected,
              elmChild.states.completed,
              elmChild.states.requestedmoreinfo
            )
          );
        });
      });
    });

    // this.workflowStages.push({
    //   stageName: this.stagesform.value.stageName,
    //   stageValue: this.stagesform.value.stageValue,
    //   states: [],
    //   isExpand: true,
    //   isActive: true,
    // });
    // this.formArr.push(this.initItemRows());
    // console.log(this.workflowStages);
  }
  newStagesCreated(mode) {
    this.stagesform.controls["stageName"].reset();
    this.stagesform.controls["stageValue"].reset();

    this.newStages = mode;
  }
  stageCollapsed(mode, listIndex) {
    for (let x = 0; x < this.workflowStages.length; x++) {
      if (x == listIndex) {
        this.workflowStages[x]["isExpand"] = true;
      }
    }
    this.stageListIndex = listIndex;
    this.stagesCollapsed = mode;
  }
  stageExpanded(mode, listIndex) {
    for (let x = 0; x < this.workflowStages.length; x++) {
      if (x == listIndex) {
        this.workflowStages[x]["isExpand"] = false;
      } else {
        this.workflowStages[x]["isExpand"] = true;
      }
    }
    this.stageListIndex = listIndex;
    this.stagesCollapsed = mode;
  }

  setWorkflowStage(evt) {
    let stageValue = evt.target.value;
    stageValue = stageValue.replace(/ /g, "_").toLowerCase();
    this.stagesform.patchValue({
      stageValue: stageValue,
    });
    this.unsaved = true;
  }
  saveStages() {
    if (this.stagesform.invalid) {
      this.toastr.error("Stage Name is mandatory", "Error");
      return;
    }
    this.workflowStages.push({
      stageName: this.stagesform.value.stageName,
      stageValue: this.stagesform.value.stageValue,
      states: [],
      isExpand: true,
      isActive: true,
    });
    this.formArr.push(
      this.initItemRows(
        "Pending has not started",
        "In Progress",
        "Approved",
        "Rejected",
        "Completed",
        "Request More Info"
      )
    );
    this.newStages = true;
  }
  saveStates() {
    this.ngxService.start();
    this.unsaved = false;
    this.stagesform.value.workflowStates.forEach((element, index) => {
      this.workflowStages[index]["states"] = element;
    });
    if (this.editStageName.length > 0) {
      this.editStageName.forEach((element) => {
        this.workflowStages[element.index].stageName = element.stageName;
        this.workflowStages[element.index].stageValue = element.stageName
          .replace(/ /g, "_")
          .toLowerCase();
      });
    }
    const objData = {
      workflowdata: this.workflowStages,
    };
    this.updateStates(objData);
  }

  updateStates(objData) {
    this.api.saveWorkFlowStages(objData, this.checkedoutdata[0]._id).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res && Number(res.responseCode) === 201) {
          this.toastr.success(
            "Workflow Stages has been added successfully! ",
            "Success!"
          );
          //this.closeStagesPopup();
        } else {
          this.toastr.error("Saving Workflow Stages failed! ", "Error!");
        }
      },
      (err) => {
        console.log(err);
        this.toastr.error(err, "Error");
        this.ngxService.stop();
      }
    );
  }

  deleteState(index: number) {
    this.swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to delete this state/status.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.workflowStages.splice(index, 1);
          this.formArr.removeAt(index);
          const objData = {
            workflowdata: this.workflowStages,
          };
          this.updateStates(objData);
        }
      });
  }
  onShowChanged(event, index) {
    this.workflowStages[index].isActive = event.target.checked;
  }
  onStageNameChanged(stageName, index) {
    this.workflowStages[index].stageName = stageName;
    this.workflowStages[index].stageValue = stageName
      .replace(/ /g, "_")
      .toLowerCase();
  }
  getSelectionCursor(event, index) {
    let chkPush = false;
    this.editStageName.forEach((element) => {
      if (element.index == index) {
        chkPush = true;
      }
    });
    if (!chkPush) {
      this.editStageName[index] = { stageName: "", index: "" };
    }
    this.editStageName[index]["stageName"] = event.target.innerText;
    this.editStageName[index]["index"] = index;
  }
  jumpCursor(index) {
    var range = window.getSelection().getRangeAt(0);
    var length = document.getElementById("divStgname" + index).textContent
      .length;
    if (range.startOffset === 10 && length === 10) {
      this.setCaret(index);
    }
  }
  setCaret(index) {
    var element = document.getElementById("divStgname" + index);
    var range = document.createRange();
    var node;
    node = document.getElementById("divStgname" + index);
    range.setStart(node.childNodes[3], 3);
    var sel = window.getSelection();
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    element.focus();
  }
  closeStages() {
    if (this.unsaved) {
      this.swalWithBootstrapButtons
        .fire({
          title: "You have unsaved changes on the form?",
          text: "Please save your changes first",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.unsaved = true;
          }
        });
    }
    if (!this.unsaved) {
      this.closeStagesPopup();
    }
  }

  closeStagesPopup() {
    this.extrasQuickPanelDisplay = false;
    this.isWorkflowPublish = false;
    this.extrasQuickPanelOffcanvasDirectionCSSClass = "offcanvas-right";
    this.extrasQuickPanelOffcanvasDirectionCSSClassStages = "offcanvas-right";
    this.workflowStages = [];
    this.editStageName = [];
    this.stagesform = this.fb.group({
      stageName: new FormControl("", [Validators.required]),
      stageValue: new FormControl("", [Validators.required]),
      workflowStates: this.fb.array([]),
    });
    this.stagesform.reset();
  }

  closeApproveStages() {
    this.extrasQuickPanelOffcanvasDirectionCSSClass = "";
    this.extrasQuickPanelOffcanvasDirectionCSSClassStages = "";
    $(document).find("#kt_approve_workflow").removeClass("offcanvas-right");
    $(document).find("#kt_approve_workflow").removeClass("offcanvas-on");
    $(document).find("#kt_approve_workflow").removeAttr("ng-reflect-ng-class");
  }

  getDataList(qformid, isDeleted) {
    this.checkedoutdata = [];
    this.publisheddata = [];
    this.chkpubCheckOut = [];
    let version = 1;
    this.api.getWorkflowList(this.api.getUserId(), qformid).subscribe(
      (res: any) => {
        this.workdata = res;
        if (this.workdata.length > 0) {
          if (
            typeof this.objUser.id !== undefined &&
            this.objUser.id == res[0]["user_id"]
          ) {
            this.verifiedUser = true;
          }
          res.forEach((element) => {
            if (element.checkedout == true) {
              this.checkedoutdata.push(element);
            } else {
              this.chkpubCheckOut.push(element);
            }
          });
        }
        this.getformnamefromqform();
        // if (isDeleted ) {
        //   if (this.workdata.length == 0) {
        //     this.updateQForms(false);
        //   }
        // }
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
    this.api.gePublishedtWorkflowList(this.api.getUserId(), qformid).subscribe(
      (res: any) => {
        res.forEach((element, index) => {
          if (index < res.length - 1) {
            if (element.form_name == res[index + 1].form_name) {
              version += 1;
            }
          }

          if (element.status == true) {
            element["version"] = version;
            this.publisheddata.push(element);
            version = 1;
            this.checkHistory(element.form_name, element.workflow_id);
          }
        });
        if (isDeleted) {
          if (res.length == 0) {
            this.updateQForms(false);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async callDataList(qformid) {
    try {
      let data = await this.api.getWorkflowList(this.api.getUserId(), qformid);
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  checkHistory(formname, workflowid) {
    this.rechistory = [];
    this.api.getCheckHistory(workflowid).subscribe(
      (res: any) => {
        let inccount = 0;
        this.qformsLoaded = true;
        res.forEach((element) => {
          if (element.status == false && formname == element.form_name) {
            inccount += 1;
            this.rechistory.push({
              id: element._id,
              formname: element.form_name,
              workflowid: workflowid,
              reccount: inccount,
            });
          }
        });
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }
  openHistory(recid, formid, workflowname, workflowid) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%";
    dialogConfig.minHeight = "calc(100vh - 90px)";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      recid: recid,
      formid: formid,
      workflowid: workflowid,
      workflowname: workflowname,
    };
    const dialogRef = this.dialog.open(WorkflowhistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && !result.cancel) {
          if (result.fetch) {
          }
        }
      },
      (error) => {
        console.error("Error while closing the dialog ", error);
      }
    );
  }

  getHistoryStatus(formname) {
    let chkstate = "hide";
    this.rechistory.forEach((element) => {
      if (formname == element.formname && element.reccount > 0) {
        chkstate = "show";
      }
    });
    return chkstate;
  }

  getformnamefromqform() {
    this.api.getformNamefromQForms(this.qformid).subscribe(
      (res: any) => {
        this.formname = res.form[0].label;
        this.username = res.form[0].createdByName;
        localStorage.setItem("formname", res.form[0].label);
        localStorage.setItem("createdBy", res.form[0].createdByName);
        this.ploader = false;
        //this.api.qformControls = res;
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }

  publishworkflowApproved(workflowid, formid) {
    const data = {
      workflowid: workflowid,
      status: true,
      checkedout: false,
    };
    this.api.generateWorkflowData(data.workflowid).subscribe((res: any) => {
      this.savePublishWorkflow(res.workflow_data[0]);
    });
  }

  publishworkflow(workflowid, formid, workflowName) {
    this.isWorkflowPublish = true;
    this.extrasQuickPanelOffcanvasDirectionCSSClass =
      "offcanvas-right offcanvas-on";
    this.workflowname = workflowName;
    this.qformid = formid;
    this.workflowId = workflowid;
    this.getPublishedWorkflowApproval(this.qformid);
    this.getFormUsers(this.qformid);
  }

  getPublishedWorkflowApproval(formId) {
    this.api.getPublishedWorkflowApproval(formId).subscribe((res: any) => {
      res.ApprovalDetails.forEach((element) => {
        this.appDesc = element.description;
        this.isApproved = element.status == "approved" ? false : true;
        this.dueDate = element.dueDate;
        this.approverId = element.assignedTo;
      });
    });
  }

  getFormUsers(formId) {
    this.userList = [];
    this.api.getUserlist(formId).subscribe((res: any) => {
      let users = res;
      const filteredUsers = users.filter(
        (user) =>
          !(
            user.userRoles.length === 1 &&
            user.userRoles[0] === "BillingAdministrator"
          )
      );

      for (let i = 0; i < filteredUsers.length; i++) {
        this.userList.push({
          value: filteredUsers[i].email,
          id: filteredUsers[i]._id,
          email: filteredUsers[i].email,
          name: filteredUsers[i].name,
        });
      }
    });
  }

  changeApprovalType(event) {
    if (event.target.checked) {
      this.isApproved = true;
    } else {
      this.isApproved = false;
    }
  }

  changeApprover(event) {
    this.approverId =
      event.target["options"][event.target["options"].selectedIndex].value;
    this.approverName =
      event.target["options"][event.target["options"].selectedIndex].text;
  }

  approveWorkflow() {
    if (this.isApproved && !this.approverId) {
      this.toastr.error("Select an Approver first");
      return;
    }
    let clientToken = localStorage.getItem("wkfiHubToken");
    clientToken = JSON.parse(atob(clientToken.split(".")[1]));
    this.dueDate = $(document).find("#txtdueDate").val();
    this.appDesc = this.appDesc;
    if (this.isApproved && this.dueDate == "") {
      this.toastr.error("Due date is required");
      return;
    }
    const ObjData = {
      form_id: this.qformid,
      clientId: clientToken["clientId"],
      userId: clientToken["id"],
      workflowId: this.workflowId,
      assignedTo: this.approverId,
      workflowName: this.workflowname,
      formName: this.formname,
      approve_reject_date: "",
      status: this.isApproved ? "pending" : "approved",
      comments: "",
      description: this.appDesc,
      dueDate: this.dueDate,
    };
    this.api.sendWorkflowForApproval(ObjData).subscribe((res: any) => {
      if (res.status == "success") {
        this.toastr.success("Workflow send for approval");
      } else {
        this.toastr.error("There is some problem in sending for approval");
      }
    });
    if (!this.isApproved) {
      this.publishworkflowApproved(this.workflowId, this.qformid);
    }
    this.closeApproveStages();
  }
  savePublishWorkflow(data) {
    const pubdata = {
      _id: data._id,
      formid: data.form_id,
      formname: data.form_name,
      workflowdata: data.workflowdata,
      stage: data.stage,
      status: data.status,
      checkedout: data.checkedout,
      userid: data.user_id,
      restartWorkflow: data.restartWorkflow,
    };
    this.api.publishWorkFlow(pubdata).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res && Number(res.responseCode) === 200) {
          this.toastr.success("Workflow Published successfully", "Success");
          this.updateQForms(true);
          this.getDataList(pubdata.formid, false);
        } else if (res && Number(res.responseCode) === 201) {
          this.toastr.success(
            "The new version of Workflow has been created successfully",
            "Success"
          );
          this.getDataList(pubdata.formid, false);
        } else {
          this.toastr.error("Workflow Published failed! ", "Error!");
        }
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  CheckedOutWorkflow(workflowid, formid) {
    const data = {
      workflowid: workflowid,
      status: true,
      checkedout: true,
    };
    this.api.setCheckedOut(data, data.workflowid).subscribe(
      (res: any) => {
        this.ngxService.stop();
        if (res && Number(res.responseCode) === 201) {
          this.toastr.success("Workflow Checked Out successfully", "Success");
          this.getDataList(formid, false);
        } else {
          this.toastr.error("Workflow Checked Out failed! ", "Error!");
        }
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
      }
    );
  }

  copyworkflow(workflowid, formid, statusmode) {
    this.confirmFormDeleteDialogRef = null;
    this.confirmFormDeleteDialogRef = this.dialog.open(CopyworkflowComponent, {
      width: "400px",
      id: "copyworkflow",
      disableClose: true,
      data: {
        workflowid: workflowid,
        formid: formid,
        mode: statusmode,
        workflowname: "",
        header: "Copy Workflow?",
        postivebtnLabel: "Submit",
        negativeBtnLabel: "Cancel",
      },
    });

    this.confirmFormDeleteDialogRef.afterClosed().subscribe(
      (result) => {
        if (result && !result.cancel) {
          if (result.fetch) {
            this.getDataList(formid, false);
          }
        }
      },
      (error) => {
        console.error("Error while closing the dialog ", error);
      }
    );
  }

  openConfirmFormDelete(id, workflowid, formname, deletemode) {
    const checkPublished = deletemode == "published" ? true : false;
    this.confirmFormDeleteDialogRef = null;
    this.confirmFormDeleteDialogRef = this.dialog.open(ConfirmdeleteComponent, {
      width: "400px",
      id: "confirmformdelete",
      disableClose: true,
      data: {
        id: id,
        workflowid: workflowid,
        name: formname,
        mode: deletemode,
        header: "Delete Form?",
        content:
          "Are you sure to delete this form? Deletion will include Workflow rules also.",
        postivebtnLabel: "Yes, Delete",
        negativeBtnLabel: "Cancel",
      },
    });

    this.confirmFormDeleteDialogRef.afterClosed().subscribe(
      (result) => {
        if (result && !result.cancel) {
          if (result.fetch) {
            this.getDataList(this.qformid, checkPublished);
          }
        }
      },
      (error) => {
        console.error("Error while closing the dialog ", error);
      }
    );
  }

  editWorkflow(workflowid, formid) {
    this.api.generateWorkflowOnId(workflowid, formid).subscribe(
      (res: any) => {
        let restartWorkflow = false;
        this.workflowname = res.workflow_data[0].form_name;
        this.workflowstage = res.workflow_data[0].stage;
        if (typeof res.workflow_data[0].restartWorkflow === "undefined") {
          restartWorkflow = false;
        } else {
          restartWorkflow = res.workflow_data[0].restartWorkflow;
        }
        this.QFFlowDialog(
          "Edit Workflow",
          workflowid,
          res.workflow_data[0].form_name,
          res.workflow_data[0].stage,
          restartWorkflow
        );
      },
      (err) => {
        console.log(err);
        this.ngxService.stop();
      }
    );
  }

  private getLogoURL(): string {
    let result = "logo.png";
    return `../../../../assets/media/logos/${result}`;
  }

  openDialog() {
    this.QFFlowDialog("New Workflow", 0, "", "", false);
  }

  generateUserId(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        let user = JSON.parse(atob(this.access_token.split(".")[1]));
        this.objUser = user;
        this.api.setUserId(user.id);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  invokeDatePicker() {
    activateDatepicker();
  }

  ngAfterViewInit() {
    this.invokeDatePicker();

    if (this.ktHeaderMenu) {
      for (const key in this.headerMenuHTMLAttributes) {
        if (this.headerMenuHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeaderMenu.nativeElement.attributes[key] =
            this.headerMenuHTMLAttributes[key];
        }
      }
    }

    if (this.ktAside) {
      for (const key in this.asideHTMLAttributes) {
        if (this.asideHTMLAttributes.hasOwnProperty(key)) {
          this.ktAside.nativeElement.attributes[key] =
            this.asideHTMLAttributes[key];
        }
      }
    }

    if (this.ktHeaderMobile) {
      for (const key in this.headerMobileAttributes) {
        if (this.headerMobileAttributes.hasOwnProperty(key)) {
          this.ktHeaderMobile.nativeElement.attributes[key] =
            this.headerMobileAttributes[key];
        }
      }
    }

    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[key] =
            this.headerHTMLAttributes[key];
        }
      }
    }
    QFLayoutContent.init("kt_content");
    QFUtil.ready(() => {
      QFLayoutHeader.init("kt_header", "kt_header_mobile");
      QFLayoutHeaderMenu.init("kt_header_menu", "kt_header_menu_wrapper");
      QFLayoutBrand.init("kt_brand");
      QFLayoutAside.init("kt_aside");
      QFLayoutAsideMenu.init("kt_aside_menu");
      if (this.asideSelfMinimizeToggle) {
        QFLayoutAsideToggle.init("kt_aside_toggle");
      }
      QFLayoutStickyCard.init("kt_page_sticky_card");
      QFLayoutStretchedCard.init("kt_page_stretched_card");
    });
  }

  QFFlowDialog(query, _id, _workflowname, _workflowstage, _restartWorkflow) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%";
    dialogConfig.minHeight = "calc(100vh - 90px)";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      description: query,
      workflowid: _id,
      form_id: this.qformid,
      wlength: this.workdata.length,
      workflowname: _workflowname,
      workflowstage: _workflowstage,
      formname: this.formname,
      restartWorkflow: _restartWorkflow,
    };
    const dialogRef = this.dialog.open(NewworkflowComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && !result.cancel) {
          if (result.fetch) {
            this.getDataList(this.qformid, false);
          }
        }
      },
      (error) => {
        console.error("Error while closing the dialog ", error);
      }
    );
  }

  updateQForms(status) {
    const data = {
      formId: this.qformid,
      status: status,
    };
    this.api.updateQFormWithWorkflow(data).subscribe(
      (res: any) => {
        if (res) {
          console.log(res);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
